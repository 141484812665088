@import-normalize;

@font-face {
    font-family: 'Mont';
    font-weight: 300;
    src: local('Mont'), url(../public/assets/fonts/Mont-Book.ttf) format('truetype');
}

@font-face {
    font-family: 'Mont';
    font-weight: 400;
    src: local('Mont'), url(../public/assets/fonts/Mont-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Mont';
    font-weight: 600;
    src: local('Mont'), url(../public/assets/fonts/Mont-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Mont';
    font-weight: 700;
    src: local('Mont'), url(../public/assets/fonts/Mont-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Mont';
    font-weight: 800;
    src: local('Mont'), url(../public/assets/fonts/Mont-Heavy.ttf) format('truetype');
}

:root {
    --shadow-1: 0px 6px 12px rgba(41, 43, 50, 0.12);
    --shadow-2: 0px 4px 12px rgba(41, 43, 50, 0.04);

    --blue: #262161;
    --blue-light: rgba(15, 13, 38, 0.35);
    --aqua: #75D6C2;
    --lavender: #F0C4E5;
    --orange: #FF6633;
    --yellow: #FFB800;

    --info-active: #5487F5;
    --info-muted: #E5EDFE;
    --success-active: #00C48C;
    --success-muted: #D9F6EE;
    --alert-active: #F1B422;
    --alert-muted: #FFF8E7;
    --warning-active: #FFA26B;
    --warning-muted: #FFF1E9;
    --error-active: #FF647C;
    --error-muted: #FFE8EB;

    --grey-9: #262161;
    --grey-8: #45495A;
    --grey-7: #545A77;
    --grey-6: #9299BE;
    --grey-5: #BBC2DC;
    --grey-4: #DADFF2;
    --grey-3: #E7EBFB;
    --grey-2: #F1F4FF;
    --grey-1: #F6F8FD;

    --background: var(--grey-2);
    --background-alt: #ffffff;
    --text: #090909;
    --text-alt: #FFFFFF;

    --splash-index: 1070;
    --loader-index: 1040;
    --modal-index: 1030;
    --dropdown-index: 1020;
    --card-index: 1010;

    --pulse-color: var(--info-active);
}
* {
    outline: none;
}
*, ::after, ::before {
    box-sizing: border-box;
}
*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
*::-webkit-scrollbar-track {
    background: var(--background);
}
*::-webkit-scrollbar-thumb {
    border: 1.5px solid var(--background);
    border-radius: 4px;
    background-color: var(--grey-5);
}
*::-webkit-scrollbar-thumb:hover {
    background-color: var(--grey-6);
}
html, body {
    width: 100%;
}
body {
    font-family: 'Mont', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    margin: 0;
    color: var(--text);
    background-color: var(--background);
    overflow: hidden auto;
    overflow: hidden overlay;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

input,
button,
select,
textarea,
optgroup,
option,
label {
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: inherit;
    line-height: normal;
    padding: 0;
    color: inherit;
}
#root {
    width: 100%;
}

div[role=presentation] {
    display: flex;
  }



.introjs-hint-pulse {
    background-color: var(--pulse-color);
}

.introjs-hint:hover > .introjs-hint-pulse {
    background-color: var(--pulse-color) !important;
}

@keyframes introjspulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 var(--pulse-color);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.introjs-tooltip {
    border-radius: 20px;
}

.introjs-tooltip a {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    line-height: 24px;
    border: none;
    border-radius: 16px;
    background-color: var(--blue);
    color: var(--text-alt);
    transform: scale(1);
    transition: all 300ms ease;
    cursor: pointer;
    text-shadow: none;
}

.introjs-tooltip p {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--grey-7);
    padding-bottom: 10px;
}

.introjs-tooltip a:hover:enabled {
    background-color: var(--blue-light);
    color: var(--blue);
    text-shadow: none;
}

.__react_component_tooltip {
    max-width: 70vw;
}

@keyframes animateIn {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
};



