.react-multi-email {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    padding: .4em .5em;
    background: rgb(241, 244, 255);
    color: var(--blue);
    border-radius: 14px;
    transition: box-shadow .1s ease,border-color .1s ease;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start
}

.react-multi-email>span[data-placeholder] {
    display: none;
    position: absolute;
    left: .5em;
    top: .4em;
    padding: .4em;
    line-height: 1.21428571em;
    color: var(--blue);
}

.react-multi-email.focused {
    border-color: #85b7d9;
    background: rgb(241, 244, 255);
}

.react-multi-email.empty>span[data-placeholder] {
    display: inline;
    color: var(--blue);
    font-weight: 300;
}

.react-multi-email.fill>span[data-placeholder] {
    display: none
}

.react-multi-email>input {
    flex: 1 1;
    width: auto!important;
    outline: none!important;
    border: 0!important;
    display: inline-block!important;
    line-height: 1;
    vertical-align: baseline!important;
    padding: .4em .1em!important;
    background: transparent;
}

.react-multi-email [data-tag] {
    line-height: 1;
    vertical-align: baseline;
    margin: .14285714em;
    background-color: var(--blue);
    background-image: none;
    padding: .4em .8em;
    color: #fff;
    text-transform: none;
    font-weight: 600;
    border: 0 solid transparent;
    border-radius: 14px;
    transition: background .1s ease;
    font-size: .8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%
}

.react-multi-email [data-tag] [data-tag-item] {
    max-width: 100%;
    overflow: hidden
}

.react-multi-email [data-tag]:first-child {
    margin-left: 0
}

.react-multi-email [data-tag] [data-tag-handle] {
    margin-left: .833em;
    cursor: pointer
}

